import { isEmpty, pick } from 'lodash';
import { observable, makeObservable, runInAction, computed, toJS } from 'mobx';
import { deploymentConfig } from '../../deployment-config';
import {
  // oldInitChaat,
  // invokeAudioProcessingNext,
  // invokeAwsTranscribeNext,
  newInitChaat,
} from '../../editorial/chaat/chaat-crud';
import {
  consoleAddAuditLogMessage,
  deleteAllEpisodeDocs,
  importUnitData,
  resetChaatCues,
  resetVersionDocs,
  sniffUnitHasDuplicateWordIds,
  sniffUnitHasUpperCaseWordIds,
  trimTranslationVersions,
  unitBase36ToLowercase,
} from '../../editorial/db/mutation-actions';
import {
  BaseMetadata,
  CatalogEntityKind,
  InfoV5Data,
  // MetadataFlavor,
  UnitData,
  // UnitL1,
  // UnitL1s,
  // UnitL1Version,
  WorkflowStatus,
} from '../catalog-types';
import { Volume } from './volume';
// import { BogotaDataLoader } from '../bogota/bogota-data-loader';
// import { ActivityGuideV3Data, BogotaStoryData } from '../bogota/bogota-types';
import { ActivityGuideV3Data } from '../bogota/bogota-types';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';
import { Channel } from './channel';
import { ElementId } from '@tikka/basic-types';
import { LocaleCode } from '@utils/util-types';
import {
  updateSentenceVersions,
  updateStructuralVersions,
  updateTranslationVersions,
  updateWordGroupVersions,
} from '../../editorial/db/versions-update';
import {
  epochSecondsFloat,
  invariant,
  iso8601ToEpochSeconds,
  looseDateToIso8601,
  randomSlug,
} from '../../utils';
import { slugify } from '../../misc/editorial-string-utils';
import { AppUser } from '../../editorial/models/user-manager';
import { TagType } from './tag';
import { defaultKerningConfigData } from '../../misc/constants';
import { ActivityGuide } from './activity-guide';
import { CatalogCollections, docReference } from '../db/catalog-db-paths';
import { notEmpty } from '@utils/conditionals';
import { AuthorManager } from '../db/author-manager';
import { TagManager } from '../db/tag-manager';
import { ActivityGuideManager } from '../db/activity-guide-manager';
import { VolumeManager } from '../db/volume-manager';
import { UnitManager } from '../db/unit-manager';
import { transformPlayerText } from '@utils/content-string-utils';
import { KerningConfigData } from '../../editorial/chaat/chaat-types';
import { fetchGoogleDocText } from '@masala-lib/misc/google-drive-utils';
import {
  parseJWScript,
  saveScriptDataToFirestore,
} from '@masala-lib/editorial/jw-script/jwscript';
import { Excerpt } from './excerpt';
import { ExcerptManager } from '../db/excerpt-manager';
import { VolumeCrud } from '../db/volume-crud';
import { ExcerptCrud } from '../db/excerpt-crud';
import { EntityCrud } from '../db/entity-crud';
import { UnitCrud } from '../db/unit-crud';
// import { BogotaDataBuilder } from '../bogota/bogota-player-data';

const emptyUnitData: UnitData = {
  kind: CatalogEntityKind.UNIT,
  id: '',
  name: '',
  slug: '',
};

export interface UnitInterface extends Unit {}
export class Unit
  extends BaseCatalogEntity<UnitData>
  implements CatalogEntity<UnitData>
{
  // used with 'load solo' mode to avoid dependency on singleton volume manager
  fetchedVolume: Volume;
  fetchedExcerpts: Excerpt[];

  // // temporary home until ingestion versions broken out
  // @observable
  // bogotaCatalogData: BogotaStoryData;

  constructor(d?: Partial<UnitData>) {
    super(d as UnitData);
    this.data = { ...emptyUnitData, ...d };
    makeObservable(this);
  }

  get slug() {
    return this.data.slug;
  }

  get volumeId() {
    return this.data.volumeId;
  }

  get volume(): Volume {
    if (this.fetchedVolume) {
      return this.fetchedVolume;
    } else {
      return VolumeManager.getInstance().getById(this.volumeId);
    }
  }

  async ensureFetchedVolume(): Promise<void> {
    if (!this.fetchedVolume) {
      this.fetchedVolume = await this.fetchVolume();
    }
  }

  async fetchVolume(): Promise<Volume> {
    if (!this.fetchedVolume) {
      this.fetchedVolume = await VolumeCrud.loadById(this.volumeId, {
        fetchParents: true,
      });
    }
    return this.fetchedVolume;
  }

  get excerpts(): Excerpt[] {
    if (this.fetchedExcerpts) {
      return this.fetchedExcerpts;
    }
    const manager = ExcerptManager.getInstance();
    // await this.manager.ensureLoaded(); -- assume preloaded

    const result = manager.list.filter(excerpt => excerpt.unitId === this.id);
    return result;
  }

  async fetchExcerpts(): Promise<Excerpt[]> {
    if (!this.fetchedExcerpts) {
      this.fetchedExcerpts = await ExcerptCrud.loadAllForUnit(this);
    }
    return this.fetchedExcerpts;
  }

  get volumeName(): string {
    return this.volume?.name;
  }

  get volumeNormalizedName(): string {
    return this.volume?.normalizedName;
  }

  get channelId() {
    return this.volume?.channelId;
  }

  get channelName() {
    return this.volume?.channel?.name;
  }

  get clientSlug() {
    // todo: handle for forked stories
    return this.slug;
  }

  // get isLupa(): boolean {
  //   return this.data.flavor === MetadataFlavor.LUPA;
  // }

  // true if requested status encompasses unit status
  visible(status: WorkflowStatus): boolean {
    if (this.data.workflowStatus === WorkflowStatus.PUBLISHED) {
      return true;
    }

    if (this.data.workflowStatus === status) {
      return true;
    }

    if (
      this.data.workflowStatus === WorkflowStatus.EXTERNAL_REVIEW &&
      status === WorkflowStatus.IN_PROGRESS
    ) {
      return true;
    }

    return false;
  }

  // used by volume import to match associated existing excerpts
  excerptByElementId(elementId: string) {
    return this.excerpts.find(excerpt => excerpt.data.elementId === elementId);
  }

  // // todo: revisit this
  // get isPublished(): boolean {
  //   return (
  //     this.data.workflowStatus === WorkflowStatus.PUBLISHED ||
  //     this.data.workflowStatus === WorkflowStatus.EXTERNAL_REVIEW
  //   );
  // }

  // this is actually the volume list view - rename these
  get navPath(): string {
    return `/units/${this.id}`;
  }

  get parentNavPath(): string {
    return this.volume.navPath;
  }

  get unitNavPath(): string {
    return `/units/${this.volumeId}/${this.id}`;
  }

  get infoNavPath(): string {
    return `/units/${this.id}/info`;
  }

  get creditsNavPath(): string {
    return `/units/${this.id}/credits`;
  }

  get speakersNavPath(): string {
    return `/units/${this.id}/speakers`;
  }

  get scriptReviewPath(): string {
    return this.data.dataUrl ? `/print/${this.id}` : null;
  }

  get structuralExamplePath(): string {
    return `/units/${this.id}/structuralExample`;
  }

  get assistPath(): string {
    return `/units/${this.id}/assist`;
  }

  // remove editor nav links until we can properly isolate the singleton state and ensure cleanup.
  // will instead always open href into a new tab
  // editorNavPath(locale: LocaleCode = null): string {
  //   return `/units/${this.volumeId}/${this.id}/editor/${this.resolveLocale(locale)}`;
  // }

  resolveEditorUrl(
    locale: LocaleCode = null,
    jumpId: string | ElementId = null
  ): string {
    let path = `/units/${this.volumeId}/${this.id}/editor/${this.resolveLocale(
      locale
    )}`;
    if (!!jumpId) {
      path = `${path}/${jumpId}`;
    }
    const result = deploymentConfig.consoleUrl + path;
    // console.log(`resolveEditorUrl(${locale}, ${jumpId}) -> ${result}`);
    return result;
  }

  resolveSoundbiteEditorialDocGenUrl(locale: LocaleCode = null): string {
    // this apps script source for doc generation is at:
    // https://script.google.com/home/projects/1m62KbBvuGiTiv3EQwfIc-zeif9Ny8T7VCQadc8HRRuuSykwnZKBEfth8/edit
    const appsScriptBase = 'https://script.google.com/a/macros/jiveworld.com/s';
    const deploymentId =
      'AKfycbxHZKpc11IAhhVEfn8gsrzy1IX0KwcEX5RQ0w1LyVzxJDNYjN6PcAJueEJxJf1n5jJ3/exec';
    const dataUrl = encodeURI(
      `${deploymentConfig.masalaServerUrl}/get_sb_doc_data?key=${
        this.id
      }&locale=${locale || this.l1}`
    );
    const folderName = encodeURIComponent('/Generated Story Scripts');

    const path = `${appsScriptBase}/${deploymentId}/exec?dataUrl=${dataUrl}&name=${this.name}&folderName=${folderName}`;

    return path;
  }

  get chaatUrl(): string {
    const path = `/episodes/${this.id}/chaat`;
    return deploymentConfig.consoleUrl + path;
  }

  get reviewUrl(): string {
    const path = `/episodes/${this.id}/review`;
    return deploymentConfig.consoleUrl + path;
  }

  get bogotaReviewUrl(): string {
    return this.data.dataUrl ? `/review/${this.id}` : null;
  }

  resolveLocale(locale?: LocaleCode): LocaleCode {
    return isEmpty(locale) ? this.l1Default : locale;
  }

  get l1() {
    return this.l1Default;
  }

  get l2() {
    return this.data.l2Code;
  }

  // todo: migrate usage to l1
  get l1Default(): LocaleCode {
    return this.data?.l1Code || 'en';
  }

  // todo: revisit
  get auxL1s(): LocaleCode[] {
    const str = this.channel?.data?.auxL1s || '';
    if (isEmpty(str)) {
      return [];
    } else {
      return str.split(',').map(s => s.trim()) as LocaleCode[];
    }
  }

  get releaseDateIso8601() {
    return looseDateToIso8601(this.data.releaseDate);
  }

  async clearReleaseDate() {
    await this.updatePartial({ releaseDate: null });
  }

  resolveInfo(property: keyof InfoV5Data): string {
    const unitInfo = this.data.infoV5 || ({} as InfoV5Data);
    const unitLevel = unitInfo[property];
    console.log(`unitLevel[${property}]: ${unitLevel}`);
    const raw =
      (isEmpty(unitLevel) && this.volume.data.infoV5
        ? this.volume.data.infoV5[property]
        : unitLevel) || '';
    console.log(`raw: ${raw}`);
    return transformPlayerText(raw);
  }

  get isNew(): boolean {
    return isEmpty(this.id);
  }

  get isFirstUnit(): boolean {
    return this.data.unitNumber === 1;
  }

  get isLastUnit(): boolean {
    return this.data.unitNumber === this.volume.unitCount;
  }

  get unitNumber(): number {
    return this.data.unitNumber;
  }

  async updateUnitNumber(number: number) {
    await this.updatePartial({ unitNumber: number });
  }

  fromFormData(d: UnitData) {
    console.log(`Unit.fromFormData - channel: ${this.channel}`);
    if (isEmpty(d.l1Code?.trim())) {
      d.l1Code = this.channel?.data?.l1;
    }
    if (isEmpty(d.l2Code?.trim())) {
      d.l2Code = this.channel?.data?.l2;
    }
    // if (isEmpty(d.flavor)) {
    //   d.flavor = this.volume?.data?.flavor;
    // }
    d.releaseDate = iso8601ToEpochSeconds(d.releaseIsoDate);
    super.fromFormData(d);
  }

  toFormData(): UnitData {
    // remove direct assignment to data
    // this.data.releaseIsoDate = this.releaseDateIso8601;
    return { ...this.data, releaseIsoDate: this.releaseDateIso8601 };
  }

  sanitizedSlug(slug: string, name: string): string {
    console.log(`santizieSlug - slug: ${slug}, name: ${name}`);
    if (isEmpty(slug?.trim())) {
      let baseSlug = this.volume?.slug;
      if (baseSlug === undefined) {
        console.log(`santizedSlug - warning unable to resolve volume.slug`);
        baseSlug = slugify(name);
      }
      let unitCount = this.volume?.unitCount;
      if (unitCount === undefined) {
        console.log(
          `santizedSlug - warning unable to resolve volume.unitCount`
        );
        unitCount = 0;
      }
      let candidate = `${baseSlug}-${unitCount + 1}`;
      // just use the unnumbered volume slug if tagged as 'single unit'
      if (this.volume?.singleUnit) {
        candidate = baseSlug;
      }
      const existing = UnitManager.getInstance().getBySlug(candidate);
      if (existing && existing.id !== this.id) {
        console.log(
          `warning - default slug collision: ${candidate} - this.id: ${this.id}, exising.id: ${existing.id}`
        );
        candidate = `${baseSlug}-${randomSlug(3)}`; // todo: improve
        console.log(` uniquified to ${candidate}`);
      }
      return candidate;
    } else {
      let candidate = slugify(slug);
      console.log(`unit slug candidate: ${candidate}`);
      const existing = UnitManager.getInstance().getBySlug(candidate);
      console.log(`exisiting.id: ${existing?.id}`);
      if (existing && existing.id !== this.id) {
        const message = `error - slug collision: ${candidate} - this.id: ${this.id}, exising.id: ${existing.id}`;
        console.log(message);
        global.alert(message); // TODO: @Armando, please clean up the error flow here
        throw Error(message);
      }
      return candidate;
    }
  }

  // todo: reisit
  get durationMinutes(): number {
    // const l1VersionData = this.defaultL1VersionDataForStatus(
    //   WorkflowStatus.IN_PROGRESS
    // );
    const result =
      this.data.durationMinutes ||
      // l1VersionData?.bogotaCatalogData?.durationMinutes ||
      // this.data.bogotaCatalogData?.durationMinutes || // needed for non-migrated units
      0;
    console.log(`[${this.slug}].durationMinutes: ${result}`);
    return result;
  }

  get downloadSize(): number {
    return this.data.downloadSize || 0;
  }

  get volumeDurationMinutes(): number {
    return this.volume.totalDurationMinutes;
  }

  // returns false if given label had no matching speaker label metadata record
  validateSpeakerLabel(label: string): boolean {
    label = label?.trim();
    if (this.volume?.data.speakers?.find(d => d.label === label)) {
      return true;
    }
    return false;
  }

  get speakerLabelStrings(): string[] {
    const result: string[] = [];
    result.push(...(this.volume?.data?.speakers?.map(d => d.label) ?? []));
    return result;
  }

  // returns false if given label had no matching bio (v4) or cast (v3) data
  validateSpeakerBio(label: string): boolean {
    if (!this.data.structuralContentReady) {
      return true;
    }
    label = label?.trim();
    // if (this.data.speakerLabels?.find(d => d.label === label)) {
    //   return true; // todo: think about rules for bolero v4 data
    // }
    // if (this.volume?.data?.speakerLabels?.find(d => d.label === label)) {
    //   return true;
    // }
    // todo: revisit / refactor
    const matchedSpeaker = this.volume?.data?.speakers?.find(
      d => d.label === label
    );
    if (matchedSpeaker) {
      if (matchedSpeaker.bio === 'n/a') {
        return true;
      }
      const bio = matchedSpeaker.translations[`bio:${this.defaultLocale}`];
      return !isEmpty(bio);
    } else {
      console.error(`unexpectedly missing speaker data: ${label}`);
    }
    // if (this.data.lupaCast?.find(d => d.fullName === label)) {
    //   return true;
    // }
    // return false;
    return true;
  }

  // bridge to the script editor and chaat data
  // can hopefully always be just the same as the id, but might need override
  get episodeKey(): string {
    return this.id;
  }

  get auditLogUrl(): string {
    return `${deploymentConfig.masalaServerUrl}/audit_trail_records?key=${this.episodeKey}`;
  }

  get exportScriptUrl(): string {
    return `${deploymentConfig.masalaServerUrl}/jw_script?key=${this.episodeKey}`;
  }

  get exportTimestampsUrl(): string {
    return `${deploymentConfig.masalaServerUrl}/timestamps?key=${this.episodeKey}`;
  }

  exportTranslationsUrl(locale?: LocaleCode): string {
    return `${deploymentConfig.masalaServerUrl}/translations?key=${
      this.episodeKey
    }&locale=${this.resolveLocale(locale)}`;
  }

  get importTranslationsFormUrl(): string {
    return `${deploymentConfig.masalaServerUrl}/import_translations_form?key=${this.episodeKey}`;
  }

  exportVocabUrl(locale?: LocaleCode): string {
    return `${deploymentConfig.masalaServerUrl}/all_vocab?key=${
      this.episodeKey
    }&locale=${this.resolveLocale(locale)}&download=true`;
  }

  get scriptInitted(): boolean {
    return !!this.data.scriptInitTime || this.chaatReady;
  }

  get chaatInitted(): boolean {
    return !!this.data.chaatInitTime;
  }

  get chaatReady(): boolean {
    return (
      this.data.chaatReady || !!this.data.transcriptionJob?.jobFinishTimestamp
    );
  }

  get defaultLocale(): LocaleCode {
    return this.data.l1Code || this.channel?.data?.l1;
  }

  // get transcriptionStarted(): boolean {
  //   // return !!this.data.transcriptionJob?.jobStartTimestamp;
  //   return !!this.data.chaatInitJobId;
  // }

  // get newTranscriptionStarted(): boolean {
  //   return !!this.data.chaatInitJobData?.startTimestamp;
  // }

  // get transcriptionComplete(): boolean {
  //   // return !!(this.data.chaatInitJobData.status === 'SUCCEEDED');
  //   return this.chaatReady; // || !!this.data.transcriptionJob?.jobFinishTimestamp;
  // }

  // get newTranscriptionComplete(): boolean {
  //   // return !!(this.data.chaatInitJobData.status === 'SUCCEEDED');
  //   return this.chaatReady;
  // }

  // get transcriptionStartedMinutesAgo(): number {
  //   return this.data.transcriptionJob
  //     ? Math.round(
  //         (Date.now() / 1000 - this.data.transcriptionJob.jobStartTimestamp) /
  //           60
  //       )
  //     : null;
  // }

  get transcriptionStartedMinutesAgo(): number {
    return this.data.chaatInitJobData
      ? Math.round(
          (Date.now() / 1000 - this.data.chaatInitJobData.startTimestamp) / 60
        )
      : null;
  }

  // get transcriptionJobId(): string {
  //   return this.data.transcriptionJob?.jobId;
  // }

  // get newTranscriptionJobId(): string {
  //   return this.data.chaatInitJobData?.id;
  // }

  // get processTranscribeUrl(): string {
  //   if (isEmpty(this.transcriptionJobId)) {
  //     return null;
  //   } else {
  //     return `${deploymentConfig.falconServerUrl}/process_aws_transcribe?transcribe_job_id=${this.data.transcriptionJob.jobId}`;
  //   }
  // }

  // @computed
  // get operationStatus(): string {
  //   if (this.chaatInitted && !this.transcriptionComplete) {
  //     return this.transcriptionStarted
  //       ? 'transcribing...'
  //       : 'processing audio...';
  //   } else {
  //     return super.operationStatus;
  //   }
  // }

  async ensureParents(): Promise<Volume> {
    const volume = await VolumeManager.getInstance().loadById(this.volumeId);
    volume?.ensureParents();
    return volume;
  }

  // assumes already loaded
  get channel(): Channel {
    return this.volume?.channel;
  }

  get canIngest(): boolean {
    return this.chaatReady && !isEmpty(this.data.audioFinalStoragePath);
  }

  // get canPatchIngest() {
  //   return notEmpty(this.data.dataUrl) && !this.scriptInitted;
  // }

  get baseAudioStoragePath(): string {
    invariant(!!this.volume, 'this.volume expected');
    return this.volume.baseAudioStoragePath;
  }

  get baseImageStoragePath(): string {
    invariant(!!this.volume, 'this.volume expected');
    return this.volume.baseImageStoragePath;
  }

  get chapterDataPathPrefix(): string {
    invariant(!!this.volume, 'unit.volume expected');
    // assumes that the ingest version counter has already been incremented
    const result = `${this.volume.baseDataStoragePath}/chapter-${this.l1}-${this.slug}-v${this.volume.data.pendingVersion}`;
    return result;
  }

  chapterDataPath(position: number): string {
    return `${this.chapterDataPathPrefix}-ch${position}.json`;
  }

  // async function serverInitChaat(key: string) {
  //   try {
  //     const apiUrl = `${deploymentConfig.masalaServerUrl}/chaat_init?key=${key}`;
  //     console.log(`initChaat - apiUrl: ${apiUrl}`);
  //     let result = await fetch(apiUrl);
  //     const text = await result.text();
  //     console.log(`response: ${text}`);
  //     return text;
  //   } catch (error) {
  //     // TODO: improve error handling. (first need to fix masala-server CORS handling for error responses)
  //     window.alert(error);
  //   }
  // }

  // async oldInitChaat() {
  //   try {
  //     if (!deploymentConfig.suppressAutoExport) {
  //       await this.exportAllData();
  //     }

  //     if (isEmpty(this.data.audioTranscribeUrl)) {
  //       throw Error('Audio Transcribe URL required');
  //     }
  //     if (isEmpty(this.data.audioTranscribeStorageUrl)) {
  //       await this.fetchAssets();
  //     }
  //     const result = await oldInitChaat(this.episodeKey);
  //     console.log(`result: ${JSON.stringify(result)}`);
  //   } catch (error) {
  //     console.trace();
  //     window.alert(error); // todo: cleanup error reporting
  //   }
  // }

  async newInitChaat() {
    try {
      if (!deploymentConfig.suppressAutoExport) {
        await this.exportAllData();
      }

      if (isEmpty(this.data.audioTranscribeUrl)) {
        throw Error('Audio Transcribe URL required');
      }
      let audioUrl = this.data.audioTranscribeStorageUrl;
      if (isEmpty(audioUrl)) {
        const urls = await this.fetchAssets();
        audioUrl = urls.audioTranscribeStorageUrl;
      }
      console.log(`audioUrl: ${audioUrl}`);
      const chaatAudioGCSBasePath = this.volume.baseChaatStoragePath;
      const result = await newInitChaat(
        this.episodeKey,
        audioUrl,
        chaatAudioGCSBasePath,
        this.data.l2Code
      );
      console.log(`result: ${JSON.stringify(result)}`);
    } catch (error) {
      console.trace();
      window.alert(error); // todo: cleanup error reporting
    }
  }

  // async runAudioProcessing(): Promise<string> {
  //   return await invokeAudioProcessingNext(
  //     this.episodeKey,
  //     true /*trigger aws*/
  //   );
  // }

  // async runTranscribe(): Promise<string> {
  //   try {
  //     const result = await invokeAwsTranscribeNext(this.episodeKey);
  //     console.log(`aws transcribe - result: ${JSON.stringify(result)}`);
  //     return result;
  //   } catch (error) {
  //     console.trace();
  //     window.alert(error); // todo: cleanup error reporting
  //   }
  // }

  // async invokeAudioProcessingNext(): Promise<String> {
  //   const url = `${deploymentConfig.falconServerUrl}/audio_processing_next?key=${this.episodeKey}`;
  //   console.log(`invokeAudioProcessing - invoking: ${url}`);
  //   const result = await fetch(url);
  //   const text = await result.text();
  //   console.log(`response: ${text}`);
  //   return text;
  // }

  // async invokeAwsTranscribeNext(): Promise<string> {
  //   const url = `${deploymentConfig.falconServerUrl}/aws_transcribe_next?key=${this.episodeKey}`;
  //   console.log(`invokeAudioProcessing - invoking: ${url}`);
  //   const result = await fetch(url);
  //   const text = await result.text();
  //   console.log(`response: ${text}`);
  //   return text;
  // }

  // manually rerun the chaat timestamping algo. theoritically shouldn't need to run this manually,
  // but right now it's not always getting run automatically when needed
  // todo: figure out why the local invoke didn't fix things in the same way the server invoke seems to
  async runChaat() {
    const apiUrl = `${deploymentConfig.masalaServerUrl}/chaat_run?key=${this.episodeKey}`;
    console.log(`runChaat - apiUrl: ${apiUrl}`);
    let result = await fetch(apiUrl);
    const text = await result.text();
    console.log(`response: ${text}`);
    return text;
    // let result = await runChaatTimestamping(key);
    // console.log(`runChaatTimestamping: ${JSON.stringify(result)}`);
  }

  // async function processAwsTranscribe(jobId: string) {
  //   // todo fetch expected filename from firestore
  //   const apiUrl = `${deploymentConfig.falconServerUrl}/process_aws_transcribe?transcribe_job_id=${jobId}`;
  //   console.log(`runChaat - apiUrl: ${apiUrl}`);
  //   let result = await fetch(apiUrl);
  //   const text = await result.text();
  //   console.log(`response: ${text}`);
  //   return text;
  //   // let result = await runChaatTimestamping(key);
  //   // console.log(`runChaatTimestamping: ${JSON.stringify(result)}`);
  // }

  destroy = async () => {
    console.log(`Unit[${this.id}].destroy`);
    await this.resetAllData();
    await UnitManager.getInstance().delete(this);
  };

  async resetAllData(): Promise<void> {
    await this.exportAllData();
    let result = await deleteAllEpisodeDocs(this.episodeKey);
    console.log(`deleteAllEpisodeDocs: ${JSON.stringify(result)}`);
  }

  async resetChaatCues(): Promise<void> {
    await this.exportAllData();
    let result = await resetChaatCues(this.episodeKey);
    console.log(`resetChaatCues: ${JSON.stringify(result)}`);
  }

  async resetVersionData(): Promise<void> {
    await this.exportAllData();
    let result = await resetVersionDocs(this.episodeKey);
    console.log(`resetVersionDocs: ${JSON.stringify(result)}`);
  }

  async trimTranslationVersions(): Promise<void> {
    await this.exportAllData();
    const oneWeekAgo = epochSecondsFloat() - 60 * 60 * 24 * 7;
    let result = await trimTranslationVersions(this.episodeKey, oneWeekAgo);
    console.log(`trimOlderSentenceVersions: ${JSON.stringify(result)}`);
  }

  async repairWordIds(): Promise<string> {
    const repairNeeded = await sniffUnitHasUpperCaseWordIds(this.episodeKey);
    if (repairNeeded) {
      await this.exportAllData();
      this.setPendingOperation(`repairing word ids...`);
      await unitBase36ToLowercase(this.episodeKey);
      this.clearPendingOperation();
      console.log(
        `repairWordIds - slug: ${this.slug}, key: ${this.episodeKey}: complete`
      );
      return 'complete';
    } else {
      console.log(
        `repairWordIds - slug: ${this.slug}, key: ${this.episodeKey}: skipped`
      );
      return 'skipped';
    }
  }

  get crud(): EntityCrud<Unit, UnitData> {
    return UnitCrud;
  }

  theManager(): EntityManager<CatalogEntity<UnitData>, UnitData> {
    return UnitManager.getInstance() as unknown as EntityManager<
      CatalogEntity<UnitData>,
      UnitData
    >;
  }

  get versionedSlug() {
    return `${this.slug}-v${this.data.version}`;
  }

  // get oldReviewToolUrl(): string {
  //   return `${OLD_REVIEW_TOOL_URL}${this.versionedSlug}?ts=${Date.now()}`;
  // }

  async importFromUrl(sourceUrl: string): Promise<void> {
    if (sourceUrl.endsWith('.json')) {
      await this.importFromExportedUrl(sourceUrl);
    } else {
      await this.importFromJwScript(sourceUrl);
    }
  }

  async importFromExportedUrl(sourceUrl: string): Promise<void> {
    consoleAddAuditLogMessage(
      this.episodeKey,
      `importFromExportedUrl(${sourceUrl})`
    );
    this.setPendingOperation('importing export data...');
    const response = await fetch(sourceUrl);
    const text = await response.text();
    const data = JSON.parse(text);
    if (!data.unitMetadataDoc) {
      throw Error(`invalid export data`);
    }
    Object.assign(
      data.unitMetadataDoc,
      // don't overwrite these properties
      pick(this.data, [
        'id',
        'volumeId',
        'slug',
        'name',
        'pendingOperation',
        'version',
        'unitL1s', // preserve prior ingestion state
        'bogotaCatalogData', // holds original catalog import version number
        'workflowStatus',
        'linearUrl',
      ])
    );
    const { volumeDoc, ...unitData } = data;
    await importUnitData(this.id, unitData);
    if (volumeDoc) {
      // todo: consider backing up prior volume data before overwriting with import data
      // console.log(
      //   `importing volume speakers: ${JSON.stringify(volumeDoc.speakers)}`
      // );
      // console.log(`old data: ${JSON.stringify(this.volume.data.speakers)}`);
      const volumeUpdateData = {
        flavor: volumeDoc.flavor,
        infoV5: volumeDoc.infoV5,
        creditsData: volumeDoc.creditsData,
        speakers: volumeDoc.speakers,
        tagTypeSlugs: volumeDoc.tagTypeSlugs,
      };
      console.log(`volume update data: ${volumeUpdateData}`);
      await this.volume.updatePartial(volumeUpdateData);
    }
    this.clearPendingOperation();
  }

  // async importFromJwScript(scriptUrl: string): Promise<void> {
  //   consoleAddAuditLogMessage(
  //     this.episodeKey,
  //     `importFromJwScript(${scriptUrl})`
  //   );
  //   this.setPendingOperation('importing jw script...');
  //   await this.updatePartial({ scriptImportSourceUrl: scriptUrl });

  //   const scriptText = await fetchGoogleDocText(scriptUrl);
  //   const parsed = parseJWScript(scriptText, null);
  //   await saveScriptDataToFirestore(this.episodeKey, parsed);

  //   await this.importMetadata();
  //   console.log('parse script - complete');
  //   await this.fetchAssets();
  //   console.log('fetch assets - complete');
  // }

  async importFromJwScript(scriptUrl: string): Promise<void> {
    consoleAddAuditLogMessage(
      this.episodeKey,
      `importFromJwScript(${scriptUrl})`
    );
    this.setPendingOperation('importing jw script...');
    await this.updatePartial({ scriptImportSourceUrl: scriptUrl });

    const scriptText = await fetchGoogleDocText(scriptUrl);
    const parsed = parseJWScript(scriptText);
    await saveScriptDataToFirestore(this.episodeKey, parsed);

    // await this.importMetadata();
    console.log('parse script - complete');
    await this.fetchAssets();
    console.log('fetch assets - complete');
  }

  async ensureInfoData() {
    if (!this.data?.infoV5) {
      await this.updatePartial({ infoV5: {} });
    }
  }

  async ensureCreditsData() {
    if (!this.volume?.data?.creditsData) {
      await this.volume.updatePartial({ creditsData: {} });
    }
    // // todo: ensure this is assigned when first created
    // if (!this.data.flavor && this.volume?.data?.flavor) {
    //   await this.updatePartial({ flavor: this.volume.data.flavor });
    // }
  }

  async ensureSpeakersData() {
    if (!this.volume?.data?.speakers) {
      await this.volume.updatePartial({ speakers: [] });
    }
  }

  // // TODO: most of this can be shredded, but some of the ensure* operations
  // // might still be important
  // async importMetadata() {
  //   this.setPendingOperation('importing metadata...');
  //   const dataLoader = new BogotaDataLoader();
  //   await dataLoader.loadAndParseMetadataBlocks(this);
  //   const unitUpdateData = dataLoader.unitMetadata;
  //   console.log(`unit update data: ${JSON.stringify(unitUpdateData)}`);
  //   const volumeUpdateData = dataLoader.volumeMetadata;
  //   console.log(`volume update data: ${JSON.stringify(volumeUpdateData)}`);
  //   if (!unitUpdateData && !volumeUpdateData) {
  //     console.log('import metadata - skipped');
  //     if (this.data.metadataUrl) {
  //       console.log('force removing orphan metadataUrl');
  //       this.updatePartial({ metadataUrl: null });
  //     }
  //     await this.ensureInfoData();
  //     await this.ensureCreditsData();
  //     await this.ensureSpeakersData();
  //     this.clearPendingOperation();
  //     return;
  //   }
  //   if (unitUpdateData) {
  //     this.updatePartial(unitUpdateData);
  //   }

  //   if (volumeUpdateData) {
  //     if (this.volume) {
  //       if (volumeUpdateData.speakers && this.volume.hasSpeakerData) {
  //         console.log(
  //           `update speaker data: ${JSON.stringify(
  //             volumeUpdateData.speakers
  //           )}, existing count: ${this.volume.data.speakers?.length}`
  //         );
  //         throw Error(
  //           `refusing to overwrite existing volume speaker data with imported metadata - please either reset the existing volume level speaker data or remove/rename the metadata SPEAKERS block`
  //         );
  //         // window.alert(
  //         //   'beware, ignoring speaker data because data already exists. please reset the existing speaker data if you want to reimport'
  //         // );
  //         // volumeUpdateData.speakers = undefined;
  //       }
  //       this.volume.updatePartial(volumeUpdateData);
  //     } else {
  //       // TODO: figure out why this can happen
  //       console.log(
  //         `ERROR - unexpectedly missing 'volume' during importMetadata`
  //       );
  //     }
  //   }

  //   if (isEmpty(this.volume.data.speakers)) {
  //     // window.alert('beware, missing speaker data');
  //     if (!this.volume.data.speakers) {
  //       // probably not needed, but being paranoid
  //       this.volume.updatePartial({ speakers: [] });
  //     }
  //   }

  //   await this.ensureInfoData();
  //   await this.ensureCreditsData();
  //   await this.ensureSpeakersData();

  //   await this.fetchAssets();
  //   await this.zorchMetadataBlocksDoc();
  //   console.log('import metadata - complete');
  // }

  async moveInfoToVolume() {
    const { partSuffix, ...volumeInfo } = this.data.infoV5;
    await this.volume.updatePartial({ infoV5: volumeInfo });
    const unitInfo = {
      partSuffix,
      // there should be a better way to clear these properties
      titleL2: '',
      titleL1: '',
      descriptionL2: '',
      descriptionL1: '',
      taglineL2: '',
      taglineL1: '',
      weblink: '',
      originalBroadcastDate: '',
      seasonNumber: '',
    };
    await this.updatePartial({ infoV5: unitInfo });
  }

  // // the script editor level metadata elements wont be needed after import moving forward
  // async zorchMetadataBlocksDoc() {
  //   const paths = new DbPaths(db, this.episodeKey);
  //   console.log(`${this.slug} - zorchMetadataBlocksDoc`);
  //   await paths.metadataBlocksDocRef.set({ items: {} });
  // }

  // get ingestedAt(): string {
  //   return (
  //     this.data.ingestedAtIso ||
  //     new Date(this.data.ingestedAt * 1000).toISOString()
  //   );
  // }

  // async ingest(): Promise<string> {
  //   await this.ensureL1VersionData();
  //   if (!this.canPatchIngest) {
  //     await this.importMetadata();
  //     await this.fetchAssets();
  //     if (!this.volume.hasSpeakerData) {
  //       throw Error('missing volume speaker data');
  //     }
  //   }
  //   consoleAddAuditLogMessage(this.episodeKey, 'ingest');
  //   this.setPendingOperation('ingesting...');
  //   const apiUrl = `${deploymentConfig.masalaServerUrl}/ingest?unitId=${this.id}&locale=${this.l1Default}`;
  //   console.log(`ingest - apiUrl: ${apiUrl}`);
  //   let response = await fetch(apiUrl);
  //   if (response.status !== 200) {
  //     const responseText: string = await response.text();
  //     throw Error(`invoke failed: ${responseText}`);
  //   }
  //   const data = await response.json();
  //   console.log(`response: ${JSON.stringify(data)}`);
  //   // await this.volume.updateBogotaData(); // TODO: this is probably using stale data
  //   // console.log(
  //   //   `before track unitl1.int: ${JSON.stringify(
  //   //     this.defaultUnitL1.internalVersion
  //   //   )}`
  //   // );
  //   // // await this.trackNewIngestion();
  //   // console.log(
  //   //   `after track unitl1.int: ${JSON.stringify(
  //   //     this.defaultUnitL1.internalVersion
  //   //   )}`
  //   // );

  //   this.clearPendingOperation();
  //   return data.dataUrl;
  // }

  // async ensureBogotaVocabMigrationMap() {
  //   if (this.needsBogotaVocabMigrationMap) {
  //     await this.generateBogotaVocabMigrationMap();
  //   }
  // }

  // get needsBogotaVocabMigrationMap(): boolean {
  //   return (
  //     this.canIngest &&
  //     this.data.workflowStatus === WorkflowStatus.PUBLISHED &&
  //     isEmpty(this.data.bogotaVocabMigrationMap)
  //   );
  // }

  // async generateBogotaVocabMigrationMap() {
  //   const builder = new BogotaDataBuilder(this, this.l1Default);
  //   try {
  //     const map = await builder.buildVocabMigrationMap();
  //     this.updatePartialNoDirty({ bogotaVocabMigrationMap: map });
  //   } catch (error) {
  //     console.trace();
  //     window.alert(error + ` - ${this.name}`); // todo: cleanup error reporting
  //   }
  // }

  // async resetBogotaVocabMigrationMap() {
  //   this.updatePartialNoDirty({ bogotaVocabMigrationMap: null });
  // }

  async fetchAssets(): Promise<{
    // the subset that we care about
    audioTranscribeStorageUrl: string;
  }> {
    this.setPendingOperation('fetching assets...');
    const apiUrl = `${deploymentConfig.masalaServerUrl}/fetchAssets?unitId=${this.id}`;
    console.log(`fetchAudio - apiUrl: ${apiUrl}`);
    let response = await fetch(apiUrl);
    const data = await response.json();
    console.log(`response: ${JSON.stringify(data)}`);
    if (data.error) {
      throw Error(data.error);
    }
    this.clearPendingOperation();
    return data;
  }

  // async publishCatalogLevel(level: string): Promise<void> {
  //   await this.publishCatalogForStatus(catalogLevelToStatus(level));
  // }

  // async publishCatalogForStatus(status: WorkflowStatus): Promise<void> {
  //   this.setPendingOperation('publishing catalog...');
  //   await (await this.ensuredChannel()).publishCatalogForStatus(status);
  //   this.clearPendingOperation();
  // }

  // async publishReviewCatalogs(): Promise<void> {
  //   this.setPendingOperation('publishing catalogs...');
  //   await (await this.ensuredChannel()).publishReviewCatalogs();
  //   this.clearPendingOperation();
  // }

  // async publishInternalCatalog(): Promise<void> {
  //   this.setPendingOperation('publishing catalog...');
  //   await (await this.ensuredChannel()).publishInternalCatalog();
  //   this.clearPendingOperation();
  // }

  // async jwnextIngest(): Promise<string> {
  //   this.setPendingOperation('ingesting...');
  //   const apiUrl = `${deploymentConfig.masalaServerUrl}/jwnextIngest?unitId=${this.id}&locale=${this.l1Default}`;
  //   console.log(`jwnextIngest - apiUrl: ${apiUrl}`);
  //   let response = await fetch(apiUrl);
  //   if (response.status !== 200) {
  //     const responseText: string = await response.text();
  //     throw Error(`invoke failed: ${responseText}`);
  //   }
  //   const data = await response.json();
  //   console.log(`response: ${JSON.stringify(data)}`);

  //   this.clearPendingOperation();
  //   return data.dataUrl;
  // }

  async ensuredChannel(): Promise<Channel> {
    const volume = await this.ensureParents();
    const channel = await volume.ensureParents();
    return channel;
  }

  async trackRevisions(): Promise<void> {
    await this.exportAllData();
    consoleAddAuditLogMessage(this.episodeKey, 'trackRevisions');
    const defaultBaselineTimestamp = epochSecondsFloat();
    await this.updatePartial({
      defaultBaselineTimestamp,
      trackingEnabled: true,
    });
  }

  async untrackRevisions(): Promise<void> {
    await this.exportAllData();
    consoleAddAuditLogMessage(this.episodeKey, 'untrackRevisions');
    // const defaultBaselineTimestamp = epochSecondsFloat();
    await this.updatePartial({ trackingEnabled: false });
  }

  // async cloneFromUnit(sourceUnitId: string): Promise<string> {
  //   if (isEmpty(sourceUnitId)) {
  //     throw Error(`missing sourceUnitId param`);
  //   }
  //   this.setPendingOperation('copying from other unit...');
  //   await partialCloneEpisodeDocs(sourceUnitId, this.id);
  //   console.log('partialCloneEpisodeDocs - complete');
  //   await this.importMetadata();
  //   console.log('importMetadata - complete');
  //   this.clearPendingOperation();
  //   return 'done';
  // }

  // equivalent to the firestore trigger which creates the versions (element history) data
  async updateAllElementVersions(): Promise<void> {
    console.log(`updateAllElementVersions(${this.id})`);
    let result = await updateSentenceVersions(this.id);
    result = await updateStructuralVersions(this.id);
    result = await updateWordGroupVersions(this.id);
    result = await updateTranslationVersions(this.id);
    console.log(`updateAllElementVersions(${this.id}) complete`);
  }

  async exportAllData(): Promise<string> {
    this.setPendingOperation('exporting...');
    const apiUrl = `${deploymentConfig.masalaServerUrl}/export?unitId=${this.id}&mode=all`;
    console.log(`exportAllData - apiUrl: ${apiUrl}`);
    let response = await fetch(apiUrl);
    if (response.status !== 200) {
      const responseText: string = await response.text();
      throw new Error(`invoke failed: ${responseText}`);
    }
    const data = await response.json();
    console.log(`response: ${JSON.stringify(data)}`);
    this.clearPendingOperation();
    return data.dataUrl;
  }

  // assumes only run from server
  // async backupIfNeeded(): Promise<boolean> {
  //   if (this.backupNeeded) {
  //     await this.exportAllData();
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  get backupNeeded(): boolean {
    const oneHalfDayAgo = epochSecondsFloat() - 12 * 60 * 60;
    return (
      this.scriptInitted &&
      this.data.updatedAt &&
      (!this.data.exportedAt ||
        (this.data.updatedAt > this.data.exportedAt &&
          this.data.exportedAt < oneHalfDayAgo))
    );
  }

  async archive(): Promise<void> {
    consoleAddAuditLogMessage(this.episodeKey, 'archive');
    await super.archive();
  }

  async unarchive(): Promise<void> {
    consoleAddAuditLogMessage(this.episodeKey, 'unarchive');
    await super.unarchive();
  }

  // still allows access by locker
  async lockBy(user: AppUser): Promise<void> {
    consoleAddAuditLogMessage(this.episodeKey, 'lockBy');
    this.validateWriteAccess(user);
    await this.updatePartial({ lockedByUserId: user.id, lockedForAll: false });
  }

  // maybe unlocked by any admin, but blocks write access by any user
  async lockByForAll(user: AppUser): Promise<void> {
    consoleAddAuditLogMessage(this.episodeKey, 'lockByForAll');
    this.validateWriteAccess(user);
    await this.updatePartial({ lockedByUserId: user.id, lockedForAll: true });
  }

  async unlockBy(user: AppUser): Promise<void> {
    consoleAddAuditLogMessage(this.episodeKey, 'unlockBy');
    // this.validateWriteAccess(user);
    if (user.isAdmin) {
      await this.updatePartial({ lockedByUserId: null, lockedForAll: false });
    } else {
      throw Error(`admin access required`);
    }
  }

  hasReadAccess(user: AppUser): boolean {
    if (user) {
      // if (user.isReviewer) {
      //   // return this.isReviewCopy && !this.isLocked;
      //   // for now, only units with tracking enabled can be accessed by external reviewers
      //   return this.data.trackingEnabled && !this.isLocked;
      // } else {
      //   return true;
      // }
      return true;
    } else {
      return false;
    }
  }

  hasWriteAccess(user: AppUser): boolean {
    if (user) {
      // if (user.isAdmin) {
      //   return true;
      // }
      if (this.data.lockedForAll) {
        return false;
      }
      if (user.isReviewer) {
        // return this.isReviewCopy && !this.isLocked;
        // for now, only units with tracking enabled can be accessed by external reviewers
        return this.data.trackingEnabled && !this.isLocked;
      } else {
        return !this.isLocked || this.data.lockedByUserId === user.id;
      }
    } else {
      return false;
    }
  }

  // always false for 'external reviewer' users
  hasStandardAccess(user: AppUser): boolean {
    return user && !user.isReviewer && this.hasWriteAccess(user);
  }

  get isLocked(): boolean {
    return !isEmpty(this.data.lockedByUserId);
  }

  get isReviewCopy(): boolean {
    return !isEmpty(this.data.cloneSourceUnitId);
  }

  validateWriteAccess(user: AppUser): void {
    if (!this.hasWriteAccess(user)) {
      if (this.isLocked) {
        throw Error(`locked by ${this.data.lockedByUserId}`);
      } else {
        throw Error(`no write access`);
      }
    }
  }

  /* Workflow Related */
  async setLinearUrl(url: string) {
    runInAction(() => {
      this.updatePartial({ linearUrl: url });
    });
  }

  /*
   * Tag Stuff
   *
   * Access to legacy data for reingesting old units.
   * All new tag data will be managed at the volume level
   */

  tagSlugsByType(tagType: TagType) {
    // return this.allTags.filter(tag => tag.data.tagType === tagType);
    const slugs = this.data.tagTypeSlugs
      ? this.data.tagTypeSlugs[tagType]
      : null;
    return slugs || [];
  }

  tagsByType(tagType: TagType) {
    return TagManager.getInstance().tagsByTypeSlugs(
      tagType,
      this.tagSlugsByType(tagType)
    );
  }

  tagNamesByType(tagType: TagType) {
    const volumeResult = this.volume.tagNamesByType(tagType);
    if (isEmpty(volumeResult)) {
      return this.tagsByType(tagType).map(tag => tag.name);
    } else {
      return volumeResult;
    }
  }

  get hasUnitLevelTagData(): boolean {
    return (
      notEmpty(this.apTagNames) ||
      notEmpty(this.ibTagNames) ||
      notEmpty(this.countryTagNames) ||
      notEmpty(this.topicTagNames)
    );
  }

  get apTagNames() {
    return this.tagNamesByType('ap');
  }

  get ibTagNames() {
    return this.tagNamesByType('ib');
  }

  get countryTagNames() {
    return this.tagNamesByType('country');
  }

  get topicTagNames() {
    return this.tagNamesByType('topic');
  }

  /*
   *  Activity Guide’s stuff
   */

  // beware, this assumes all activity guide instances are preloaded by manager
  get activityGuide(): ActivityGuide {
    return ActivityGuideManager.getInstance().assignedToUnit(this.id);
  }

  // consider an async version
  get activityGuideUrl() {
    const guide = this.activityGuide;
    return guide?.data?.resourceUrl;
  }

  async buildActivityGuideCatalogData(): Promise<ActivityGuideV3Data> {
    // needed until db query properly implemented
    await ActivityGuideManager.getInstance().ensureLoaded();

    const guide = this.activityGuide;
    if (!guide) {
      return null;
    }
    const authorManager = new AuthorManager();
    const author = await authorManager.loadById(guide.data.authorId);

    return {
      authorData: author?.catalogV3Data,
      authorNote: guide.data.authorNote,
      resourceUrl: guide.data.resourceUrl,
    };
  }

  /**
   * Kerning config
   */
  setKerningConfigData = (configData: KerningConfigData) => {
    this.updatePartial({ kerningConfig: configData });
  };

  get kerningConfigData() {
    return this.data.kerningConfig ?? defaultKerningConfigData;
  }

  // TODO jrw hacking updatePartial override here, make better approach
  async updatePartial(data: object): Promise<void> {
    this.data = { ...this.data, ...data }; // need to reassign to trigger the reactions
    const metadata = data as BaseMetadata;
    // if (!metadata.createdAt) {
    //   metadata.createdAt = epochSecondsFloat(); // TODO hmm I think this is wrong for updatePartial in the normal flow as well
    // }
    metadata.updatedAt = epochSecondsFloat();
    // todo: factor out a docSetMerge() function with this guard
    if (isEmpty(data)) {
      // an empty object object will nuke the entire doc!
      return;
    }
    const ref = docReference<UnitData>(
      CatalogCollections.UNIT_METADATA,
      this.id
    );
    await ref.set(data, { merge: true });
  }

  // version which doesn't touch the 'updatedAt' timestamp
  // todo: refactor
  async updatePartialNoDirty(
    data: object,
    useUpdate: boolean = false
  ): Promise<void> {
    // beware, won't correctly handle nested paths
    this.data = { ...this.data, ...data }; // need to reassign to trigger the reactions
    if (isEmpty(data)) {
      // an empty object object will nuke the entire doc!
      return;
    }
    const ref = docReference<UnitData>(
      CatalogCollections.UNIT_METADATA,
      this.id
    );
    if (useUpdate) {
      // todo: consider making the 'update' mode the default
      await ref.update(data);
    } else {
      await ref.set(data, { merge: true });
    }
  }

  // update a single path w/o merge.
  // needed to honor removed credit fields within the ingested story data
  async updatePath(path: string, data: object): Promise<void> {
    this.data = { ...this.data, ...data }; // need to reassign to trigger the reactions
    const ref = docReference<UnitData>(
      CatalogCollections.UNIT_METADATA,
      this.id
    );
    await ref.set(data, { merge: false });
  }

  // returns true if unit verbatim data has been corrupted with a duplicate word id
  async sniffForDuplicateWordIds() {
    return await sniffUnitHasDuplicateWordIds(this.id);
  }

  async validateNoDuplicateWordIds() {
    if (await this.sniffForDuplicateWordIds()) {
      throw Error('duplicate word id detected');
    }
  }

  /*
   * UnitL1 and UnitL1Version support
   */

  // get defaultUnitL1() {
  //   return this.data.unitL1s && this.data.unitL1s[this.l1Default];
  // }

  // defaultL1VersionDataForStatus(status: WorkflowStatus) {
  //   return versionDataForStatus(this.defaultUnitL1, status);
  // }

  // async trackNewIngestion() {
  //   const unitL1 = this.defaultUnitL1;
  //   unitL1.internalVersion = {
  //     versionNumber: this.data.version,
  //     // bogotaCatalogData: this.data.bogotaCatalogData,
  //     dataV3Url: this.data.dataUrl,
  //     timestamp: this.data.ingestedAt,
  //     author: '',
  //     pendingPublish: true,
  //   };
  //   await this.persistDefaultL1(unitL1);

  //   if (
  //     !this.data.workflowStatus ||
  //     this.data.workflowStatus === WorkflowStatus.PLANNED
  //   ) {
  //     await this.updatePartial({
  //       workflowStatus: WorkflowStatus.IN_PROGRESS,
  //     });
  //   }
  // }

  // async promoteToExternal() {
  //   consoleAddAuditLogMessage(this.episodeKey, 'promoteToExternal');

  //   const unitL1 = this.defaultUnitL1;
  //   unitL1.externalVersion = {
  //     ...unitL1.internalVersion,
  //     pendingPublish: true,
  //   };
  //   await this.persistDefaultL1(unitL1);
  //   if (this.data.workflowStatus === WorkflowStatus.IN_PROGRESS) {
  //     await this.updatePartial({
  //       workflowStatus: WorkflowStatus.EXTERNAL_REVIEW,
  //     });
  //   }
  // }

  // async promoteToPublic() {
  //   const unitL1 = this.defaultUnitL1;
  //   unitL1.publicVersion = {
  //     ...unitL1.externalVersion,
  //     pendingPublish: true,
  //   };
  //   await this.persistDefaultL1(unitL1);
  //   if (this.data.workflowStatus === WorkflowStatus.EXTERNAL_REVIEW) {
  //     await this.updatePartial({
  //       workflowStatus: WorkflowStatus.PUBLISHED,
  //     });
  //   }
  //   await this.publishCatalogForStatus(WorkflowStatus.PUBLISHED);
  // }

  // async promoteInternalToStaged() {
  //   consoleAddAuditLogMessage(this.episodeKey, 'promoteInternalToStaged');
  //   const unitL1 = this.defaultUnitL1;
  //   unitL1.publicVersion = {
  //     ...unitL1.internalVersion,
  //     pendingPublish: true,
  //   };
  //   await this.persistDefaultL1(unitL1);
  //   if (
  //     this.data.workflowStatus === WorkflowStatus.EXTERNAL_REVIEW ||
  //     this.data.workflowStatus === WorkflowStatus.IN_PROGRESS
  //   ) {
  //     await this.updatePartial({
  //       workflowStatus: WorkflowStatus.PUBLISHED,
  //     });
  //   }
  //   await this.publishCatalogForStatus(WorkflowStatus.PUBLISHED);
  // }

  // async markStagedAsLive(): Promise<void> {
  //   if (!this.pendingMarkLive) {
  //     console.log(`markStagedAsLive(${this.slug}) skipped`);
  //     return;
  //   }

  //   consoleAddAuditLogMessage(this.episodeKey, 'markStagedAsLive');
  //   const unitL1 = this.defaultUnitL1;

  //   unitL1.liveVersion = {
  //     ...unitL1.publicVersion,
  //     bogotaCatalogData: null,
  //     pendingPublish: false,
  //     // author: Auth.currentAlias
  //   };
  //   await this.persistDefaultL1(unitL1);
  // }

  // async clearDefaultL1PendingPublishForStatus(status: WorkflowStatus) {
  //   const unitL1 = this.defaultUnitL1;
  //   const versionData = versionDataForStatus(unitL1, status);
  //   if (versionData?.pendingPublish) {
  //     versionData.pendingPublish = false;
  //     await this.persistDefaultL1(unitL1);
  //   }
  // }

  // async persistDefaultL1(unitL1: UnitL1) {
  //   // todo: revisit this
  //   const unitL1s = {} as UnitL1s;
  //   unitL1s[this.l1Default] = unitL1;
  //   await this.updatePartialNoDirty({ unitL1s }, true /* useUpdate */);
  // }

  // // true if content has been updated since last ingestion
  // get ingestionNeeded(): boolean {
  //   const unitL1 = this.defaultUnitL1;
  //   if (!unitL1) {
  //     return false;
  //   }
  //   const ingestedAt = unitL1.internalVersion?.timestamp;
  //   const updatedAt = this.data.contentUpdatedAt;
  //   return ingestedAt && updatedAt && updatedAt > ingestedAt;
  // }
}
