import React from 'react';
import { observer } from 'mobx-react';
import { auth, conversationManager, versions } from '../models/app-root';
import { Conversation } from '@masala-lib/editorial/ui/timeline-components';
import { CommentDTO } from '@masala-lib/editorial/models/conversation-manager';
import { ElementId, IElement } from '@tikka/basic-types';
import { Box } from 'rebass';
import { computed, makeObservable, observable } from 'mobx';
import { Textcomplete } from '@masala-lib/editorial/ui/completion/core';
import { ContenteditableEditor } from '@masala-lib/editorial/ui/completion/contenteditable';
import { openVersionsDialog } from './versions-dialog';
import { MENTION_STRATEGY } from './mentions';
import { THREAD_COMMAND_STRATEGY } from './thread-commands';
import { MDBBtn } from 'mdbreact';

interface Props {
  element: IElement;
  showChanges?: boolean;
}

@observer
export class ConversationView extends React.Component<Props> {
  @observable.ref _editCommentId: ElementId;
  @observable.ref _initialText: string = '';

  inputEl: HTMLElement = null;
  completer: Textcomplete = null;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  get elementId() {
    return this.props.element.id;
  }

  @computed
  get changes() {
    return this.props.showChanges
      ? versions.getChangeMessages(this.elementId).slice(1)
      : [];
  }

  @computed
  get conversation() {
    return conversationManager.getConversation(this.elementId);
  }

  get comments() {
    return this.conversation.commentList;
  }

  get isResolved() {
    return this.conversation?.resolved;
  }

  @computed
  get changesFromBaseline() {
    return versions.getChangeMessagesFromBaseline(this.elementId);
  }

  get hasChanges() {
    return this.props.showChanges && this.changesFromBaseline?.length > 0;
  }

  get items() {
    return [...this.comments, ...this.changes].sort(
      (a, b) => a.timestamp - b.timestamp
    );
  }

  setEditCommentId(id: ElementId) {
    this._editCommentId = id;
  }

  setInitialText(text: string) {
    this._initialText = text;
  }

  get initialText() {
    return this._initialText;
  }

  get editCommentId() {
    if (
      this._editCommentId &&
      this.comments.map(c => c.id).includes(this._editCommentId)
    ) {
      return this._editCommentId;
    }
    return null;
  }

  handleCommentSubmit(message: string) {
    if (this.editCommentId) {
      conversationManager.updateComment(
        this.elementId,
        this.editCommentId,
        message
      );
      this.setEditCommentId(null);
      this.setInitialText('');
    } else {
      conversationManager.postComment(
        this.elementId,
        auth.appUser?.id,
        message
      );
    }
  }

  resolveThread = () => {
    if (!this.isResolved) {
      const command = this.hasChanges ? '/accept' : '/resolve';
      this.inputEl.focus();
      this.inputEl.ownerDocument.execCommand(
        'insertHTML',
        false,
        ` <span contenteditable=false class="command">${command}</span>&nbsp;`
      );
      this.handleSubmit();
    }
  };

  postComment = () => {
    console.log('postComment');
    console.log(`text: ${this.inputEl.innerText.trim()}`);
    if (this.inputEl.innerText.trim()) {
      this.handleSubmit();
    }
  };

  openRevertDialog = () => {
    if (!this.isResolved) {
      openVersionsDialog();
    }
  };

  handleSubmit() {
    // TODO merge this code from CommentBox into handleCommentSubmit?
    this.handleCommentSubmit(this.inputEl.innerText.replace(/\u00a0/g, ' '));
    this.inputEl.innerText = ''; // TODO probably not needed because handleCommentSubmit sets initialText
  }

  handleDeleteComment = (comment: CommentDTO) => {
    conversationManager.deleteComment(this.elementId, comment.id);
  };

  handleEditComment = (comment: CommentDTO) => {
    this.setEditCommentId(comment.id);
    this.setInitialText(comment.content);
  };

  handleKeyPress = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (
      e.which === 13 &&
      !this.completer.isShown() &&
      !e.shiftKey &&
      !e.repeat
    ) {
      this.handleSubmit();
      e.preventDefault();
    }
  };

  setInputElement = (el: HTMLElement) => {
    if (el && el !== this.inputEl) {
      el.addEventListener('keydown', this.handleKeyPress, false);
      const editor = new ContenteditableEditor(el);
      this.completer = new Textcomplete(
        editor,
        [MENTION_STRATEGY, THREAD_COMMAND_STRATEGY],
        {
          dropdown: { maxCount: 5 },
        }
      );
      el.oninput = "if(this.innerText.trim()==='') this.innerText=''" as any;
    }
    this.inputEl = el;
  };

  render() {
    const mayAccept = auth.can('manage_units');

    return (
      <div className="conversation-view">
        {this.conversation.assignee ? (
          <div className="comment-thread-assignee">
            Assigned to: {this.conversation.assignee}
          </div>
        ) : null}
        {this.hasChanges ? (
          <div className="changes-from-baseline">
            <div>Changes from Baseline:</div>
            <Conversation items={this.changesFromBaseline} />
            {!this.isResolved ? (
              mayAccept ? (
                <>
                  <MDBBtn color="info" size="sm" onClick={this.resolveThread}>
                    Accept change
                  </MDBBtn>
                  <MDBBtn
                    color="info"
                    size="sm"
                    onClick={this.openRevertDialog}
                  >
                    Revert To ...
                  </MDBBtn>
                </>
              ) : (
                <>[Pending change]</>
              )
            ) : null}
            <div> ------------- </div>
          </div>
        ) : null}
        <Conversation
          items={this.items}
          onDelete={this.handleDeleteComment}
          onEdit={this.handleEditComment}
        />
        <div className={'comment-box'}>
          <div
            className={'comment-editor'}
            ref={domElement => this.setInputElement(domElement)}
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: this.initialText }}
            placeholder="Comment"
          />
        </div>
        <>
          <MDBBtn color="info" size="sm" onClick={this.postComment}>
            Post comment
          </MDBBtn>
        </>
        {!this.isResolved && !this.hasChanges ? (
          <>
            <MDBBtn color="info" size="sm" onClick={this.resolveThread}>
              Resolve discussion
            </MDBBtn>
          </>
        ) : null}

        <Box className="debug-element-id">{this.elementId}</Box>
      </div>
    );
  }
}
