import { observer } from 'mobx-react';
import { useChannel } from './use-channel';
import { pick } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from '../../components/data-display/card';
import { DataCard } from '../../components/data-display/data-card';
import { DataItem } from '../../components/data-display/data-item';
import { Stack } from '../shared/stack';
// import { ImportCatalogForm } from './import-catalog-form';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { A } from '@masala-lib/editorial/ui/action-link';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { Auth } from '@masala-lib/editorial/db/auth';
import { Channel } from '@masala-lib/catalog/models/channel';
import { extractDomainName, notEmptyOrNA } from '@utils/string-utils';

interface ChannelDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const ChannelDetailScreen = observer(
  ({ /*closeDetail,*/ startEditing }: ChannelDetailsParams) => {
    const navigate = useNavigate();
    const channel = useChannel();
    const auth = Auth.getInstance();

    // console convenience
    (window as any).currentChannel = channel;

    if (!channel) {
      return null;
    }

    const goBack = () => {
      navigate(channel.parentNavPath);
    };

    // const handlePublishPublicCatalog = async () => {
    //   try {
    //     await channel.publishStagedCatalog();
    //   } catch (error) {
    //     channel.clearPendingOperation();
    //     window.alert(error);
    //   }
    // };

    // const handlePublishReviewCatalogs = async () => {
    //   try {
    //     await channel.publishReviewCatalogs();
    //   } catch (error) {
    //     channel.clearPendingOperation();
    //     window.alert(error);
    //   }
    // };

    // const handleMakeLive = async () => {
    //   try {
    //     if (
    //       window.confirm(
    //         'Make the currently staged catalog active on the live production server (and clears all "live pending" tags).\nAre you sure?'
    //       )
    //     ) {
    //       await channel.makeLive();
    //     }
    //   } catch (error) {
    //     channel.clearPendingOperation();
    //     window.alert(error);
    //   }
    // };

    return (
      <Page
        title={`${channel.name} ${channel.archived ? '(Archived)' : ''}`}
        onClose={goBack}
        controls={
          <PageActions>
            <>
              {auth.can('manage_channels') ? (
                <>
                  <button onClick={startEditing} className="primary-action">
                    Edit Channel
                  </button>
                  <Link to={`/volumes/new?channel=${channel.id}`}>
                    new volume
                  </Link>
                </>
              ) : null}
              <Link to={channel.volumesNavPath}>
                {channel.volumeCount} volumes
              </Link>
              <Link to={channel.unitsNavPath}>{channel.unitCount} units</Link>
              <Link to={channel.excerptsNavPath}>
                {channel.excerptCount} excerpts
              </Link>
              <DeleteActions model={channel} />
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            <DataCard
              header={
                <h4>
                  Channel: {channel.name}
                  {channel.operationStatus ? (
                    <> &mdash; {channel.operationStatus}</>
                  ) : null}
                </h4>
              }
              data={pick(channel, ['slug', 'l1', 'l2'])}
            />
            <CaliCatalogManagement channel={channel} />
            <DataCard
              header={<h5>Dashboard widget singletons</h5>}
              data={{
                ...pick(channel.data, [
                  'featuredFilterSets',
                  // 'featuredTagsCsv',
                  // 'featuredCollectionSlug',
                ]),
              }}
            />
            <DataCard
              header={<h5>End-user displayed props</h5>}
              data={{
                ...pick(channel.data, [
                  'clientChannelSlug',
                  'title',
                  'tagline',
                  'description', // markdown preview?
                  'weblink',
                  'themeColor',
                ]),
              }}
            />
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);

const CaliCatalogManagement = observer(({ channel }: { channel: Channel }) => {
  // const user = Auth.getInstance().appUser;
  const auth = Auth.getInstance();
  const canManage = auth.can('manage_channels');

  const handlePublishCaliReviewCatalog = async () => {
    try {
      await channel.publishCaliReviewCatalog();
    } catch (error) {
      channel.clearPendingOperation();
      window.alert(error);
    }
  };

  const handlePublishCaliStagedCatalog = async () => {
    try {
      await channel.invokePublishCaliStagedCatalog();
    } catch (error) {
      channel.clearPendingOperation();
      window.alert(error);
    }
  };

  const handleCaliMakeLive = async () => {
    try {
      if (window.confirm('Promote staged catalog to live\nAre you sure?')) {
        await channel.caliMakeLive();
      }
    } catch (error) {
      channel.clearPendingOperation();
      window.alert(error);
    }
  };
  const handleCaliReingestAll = async () => {
    try {
      if (window.confirm('Are you sure?')) {
        await channel.ingestAllVolumes();
      }
    } catch (error) {
      channel.clearPendingOperation();
      window.alert(error);
    }
  };

  const handleCaliPromoteAll = async () => {
    try {
      if (window.confirm('Are you sure?')) {
        await channel.caliPromoteAllVolumes();
      }
    } catch (error) {
      channel.clearPendingOperation();
      window.alert(error);
    }
  };

  const reviewVersion = channel.data.reviewCaliVersion;
  const stagedVersion = channel.data.stagedCaliVersion;
  const liveVersion = channel.data.liveCaliVersion;

  return (
    <Card header={<h5>Cali (web player) - Catalog management</h5>}>
      {reviewVersion ? (
        <DataItem
          label="Review catalog"
          value={
            <>
              <strong>{channel.reviewCatalogSlug}</strong>
              <br />
              {channel.reviewPublishAgePretty} (v
              {channel.data.reviewCaliVersion?.versionNumber}) &mdash;{' '}
              <A href={reviewVersion.dataUrl}>raw data url</A> &mdash;{' '}
              <A href={channel.reviewCatalogMagicLink}>
                {extractDomainName(channel.reviewCatalogMagicLink)}
              </A>
            </>
          }
        />
      ) : null}
      {stagedVersion ? (
        <DataItem
          label="Staged catalog"
          value={
            <>
              {/* {stagedVersion.slug} */}
              <strong>{channel.stagedCatalogSlug}</strong>
              <br />
              {channel.stagedPublishAgePretty} (v
              {channel.data.stagedCaliVersion?.versionNumber}) &mdash;{' '}
              <A href={stagedVersion.dataUrl}>raw data url</A> &mdash;{' '}
              <A href={channel.stagedCatalogMagicLink}>
                {extractDomainName(channel.stagedCatalogMagicLink)}
              </A>
            </>
          }
        />
      ) : null}
      {liveVersion ? (
        <DataItem
          label="'Live' catalog"
          value={
            <>
              {channel.data.livePublishEnvCsv}:{' '}
              <strong>{channel.liveCatalogSlug}</strong>
              <br />
              {channel.livePublishAgePretty} (v
              {channel.data.liveCaliVersion?.versionNumber}) &mdash;{' '}
              <A href={liveVersion.dataUrl}>raw data url</A> &mdash;{' '}
              <A href={channel.liveCatalogMagicLink}>
                {extractDomainName(channel.liveCatalogMagicLink)}
              </A>
            </>
          }
        />
      ) : null}
      {canManage ? (
        <DataItem
          label="actions"
          value={
            <>
              {channel.hasAutoPublishEnv ? (
                <>
                  <p>
                    <button onClick={handlePublishCaliReviewCatalog}>
                      publish cali review catalog
                    </button>{' '}
                    ({channel.reviewCatalogSlug} -&gt;{' '}
                    {channel.data.autoPublishEnvCsv})
                  </p>
                  <p>
                    <button onClick={handlePublishCaliStagedCatalog}>
                      publish cali staged catalog
                    </button>{' '}
                    ({channel.stagedCatalogSlug} -&gt;{' '}
                    {channel.data.autoPublishEnvCsv})
                  </p>
                </>
              ) : null}
              {auth.isSuperAdmin ? (
                <>
                  <p>
                    <button onClick={handleCaliReingestAll}>
                      cali reingest all volumes
                    </button>
                  </p>
                  <p>
                    <button onClick={handleCaliPromoteAll}>
                      cali promote all volumes from review to staged
                    </button>
                  </p>
                </>
              ) : null}
              {channel.hasLivePublishEnv ? (
                <p>
                  <button onClick={handleCaliMakeLive}>
                    cali promote staged to live
                  </button>{' '}
                  ({channel.liveCatalogSlug}
                  -&gt; {channel.data.livePublishEnvCsv})
                </p>
              ) : null}
            </>
          }
        />
      ) : null}
    </Card>
  );
});
