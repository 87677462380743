import { difference, isEmpty, isNil, sortBy } from 'lodash';
import {
  computed,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
} from 'mobx';
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  QuerySnapshot,
} from '../../../platform/firebase-types';
import { Auth } from '../../editorial/db/auth';
import { epochSecondsFloat, randomString } from '../../utils';
import { CatalogEntityKind, BaseMetadata } from '../catalog-types';
import { collectionReference, docReference } from './catalog-db-paths';

//
// base class for management of catalog related model entities (now persisted via firestore)
//

export interface SelectOption {
  value: string;
  label: string;
}

export const EMPTY_FILTER = '_';

export type FilterValue = string | null;

export interface Filter {
  // fieldName: FilterableFieldName;
  value: any;
  queryName?: string;
}

export type SortingOrder = 'asc' | 'desc';
export interface SortingConfig {
  fieldName: string;
  order: SortingOrder;
}

export const blankOption = { value: '', label: '[none]' };
export const allOption = { value: '', label: '[all]' };
const PAGINATION_SIZE = 500; // todo: make smaller
export const ID_LENGTH = 8; // length of random ids to generate

export interface CatalogEntity<Data extends BaseMetadata> {
  kind: CatalogEntityKind;
  id: string;
  slug: string;
  archived: boolean;

  selectLabel: string;
  filterMatch: (text: string) => boolean;

  data: Data;
  toStorageData: () => Data;
  fromStorageData: (dto: Data) => void;
  toFormData: () => Data;
  fromFormData: (dto: Data) => void;
  archive: () => void;
  unarchive: () => void;
  destroy?: () => Promise<void>;

  channelId?: string; // support for hacked partner access control
}

// needed by SearchBar component
export interface FilterViewManager<Model> {
  filterText: string;
  setFilterText: (text: string) => void;
  list: Model[];
  create: (model: Model) => Promise<Model>;
  update: (model: Model) => Promise<void>;
  delete: ({ id }: { id: string }) => Promise<void>;
  loadAll: (options: { listen: boolean }) => Promise<void>;
  fetchById: (id: string) => Model;
}

export abstract class EntityManager<
  Model extends CatalogEntity<Data>,
  Data extends BaseMetadata
> implements FilterViewManager<Model>
{
  // @observable.map
  // map of id to class instances (marshalled from the DTOs)
  // models: { [index: string]: Channel } = {}; // index = _id
  modelMap: ObservableMap<string, Model>;

  @observable.ref
  filterText = '';

  filters: ObservableMap<string, Filter>;

  @observable.ref
  showArchived = false;

  @observable.ref
  sorting: SortingConfig = {
    fieldName: 'id',
    order: 'asc',
  };

  constructor() {
    // this.modelMap = observable.map({}, {deep: false});
    this.modelMap = observable(new Map(), { deep: false });
    makeObservable(this);
    this.filters = observable(new Map());
  }

  abstract get collectionName(): string;

  abstract createModel(data: { id: string }): Model;

  get scopedChannelId(): string {
    const auth = Auth.getInstance();
    return auth.appUser?.scopedChannelId;
  }

  setFilterText(text: string) {
    runInAction(() => {
      this.filterText = text;
    });
  }

  setFilter(key: string, value: FilterValue, queryName?: string) {
    runInAction(() => {
      this.filters.set(key, { value, queryName });
    });
  }

  setSorting(fieldName: string, order: string = 'asc') {
    runInAction(() => {
      this.sorting = { fieldName: fieldName, order: order as SortingOrder };
    });
  }

  toggleShowArchived = () => {
    runInAction(() => {
      this.showArchived = !this.showArchived;
    });
  };

  setShowArchived(value: boolean) {
    this.showArchived = value;
  }

  @computed
  get rawList(): Model[] {
    const list = Array.from(this.modelMap.values());
    let result = list.filter(entity => !entity.archived || this.showArchived);
    if (this.scopedChannelId) {
      result = result.filter(
        entity => entity.channelId === this.scopedChannelId
      );
    }
    // console.log(
    //   `${this.collectionName}.rawList - result size: ${result.length}`
    // );
    return result;
  }

  @computed
  get archivedList(): Model[] {
    const list = Array.from(this.modelMap.values());
    return list.filter(entity => entity.archived);
  }

  // reflects non-text filters
  // note, trying to use a computed getter here resulted in a cycle error. not exactly sure why
  // @computed
  // get baseList(): Model[] {
  baseList(): Model[] {
    return this.rawList;
  }

  @computed
  get list(): Model[] {
    if (isEmpty(this.filterText)) {
      return this.baseList();
    } else {
      return this.baseList().filter(model =>
        model.filterMatch(this.filterText)
      );
    }
  }

  // todo: indexed db lookup
  getBySlug(slug: string): Model {
    slug = slug.trim();
    return this.rawList.find(model => model.slug === slug);
  }

  async loadOrCreateBySlug(slug: string) {
    let candidate = this.getBySlug(slug);
    if (isNil(candidate)) {
      const dto = { id: '', slug };
      const newModel = this.createModel(dto);
      candidate = await this.create(newModel);
    }
    return candidate;
  }

  // immediately returns either the cached instance if found, or a placeholder instance
  // which will be updated after the fetch if not
  fetchById(id: string): Model {
    if (isEmpty(id)) {
      return null;
    }
    const result = this.getOrCreateById(id);
    this.loadById(id); // will run async and update the already returned instance
    return result;
  }

  // get from cache, but don't reload
  getById(id: string): Model {
    if (isEmpty(id)) {
      return null;
    }
    const result = this.getOrCreateById(id);
    return result;
  }

  getOrCreateById(id: string): Model {
    let candidate = this.modelMap.get(id);
    // console.log(`getOrCreate(${id}) candidate: ${JSON.stringify(candidate)}`);
    if (isNil(candidate)) {
      candidate = this.createModel({ id });
      runInAction(() => {
        this.modelMap.set(id, candidate);
      });
    }

    return candidate;
  }

  collectionRef(): CollectionReference<Data> {
    return collectionReference<Data>(this.collectionName);
  }

  docRef(id: string): DocumentReference<Data> {
    return docReference<Data>(this.collectionName, id);
  }

  async initCollection() {
    await this.docRef('!dummy').set({ id: '!dummy' } as Data);
  }

  listenAll(): void {
    this.collectionRef().onSnapshot(querySnapshot => {
      console.log(`${this.collectionName}.onSnapshot`);
      this.applyQuerySnapshot(querySnapshot);
      // todo: error handling
    });
  }

  async loadAll(): Promise<void> {
    const querySnapshot = await this.collectionRef().get();
    this.applyQuerySnapshot(querySnapshot);
  }

  async ensureLoaded(): Promise<void> {
    if (this.modelMap.size === 0) {
      await this.loadAll();
    }
  }

  get loadedCount(): number {
    return this.modelMap.size;
  }

  applyQuerySnapshot(snapshot: QuerySnapshot<Data>): void {
    console.log(
      `${this.collectionName}.applySnapshot - count: ${snapshot.docs.length}`
    );
    const loadedIds: string[] = [];
    runInAction(() => {
      snapshot.docs.forEach(doc => {
        const id = doc.id;
        this.applyDocSnapshot(doc);
        loadedIds.push(id);
      });
      const orphanIds = difference(Array.from(this.modelMap.keys()), loadedIds);
      console.log(`orphanIds: ${orphanIds}`);
      orphanIds.forEach(id => this.modelMap.delete(id));
    });
  }

  applyDocSnapshot(docSnapshot: DocumentSnapshot<Data>): Model {
    const data = docSnapshot.data();
    if (isNil(data)) {
      console.log(
        `${this.collectionName}.ads ignoring missing data for doc.id: ${docSnapshot.id}`
      );
      // todo: should this be treated as a removal?
      return null;
    }
    if (data.id !== docSnapshot.id) {
      console.log('DOC', docSnapshot.id);
      console.log('DATA', data);
      // todo: revisit how to best recover from corrupted data
      // right now this results in partial load of data which causes mayhem
      throw Error(
        `${this.collectionName}.${docSnapshot.id}/${data.id} doc/data id mismatch`
      );
      // console.log(
      //   `error - ${this.collectionName}.${docSnapshot.id}/${data.id} doc/data id mismatch - deleting`
      // );
      // this.delete({ id: docSnapshot.id });
    }
    const model = this.getOrCreateById(data.id);
    model.fromStorageData(data);
    return model;
  }

  async loadById(id: string): Promise<Model> {
    console.log(`${this.collectionName}.loadById(${id})`);
    if (isEmpty(id)) {
      return null;
    }
    const docSnapshot = await this.docRef(id).get();
    const model = this.applyDocSnapshot(docSnapshot);
    return model;
  }

  // version which avoids any singleton dependency
  async loadSoloById(id: string): Promise<Model> {
    const data = await this.fetchMetadataById(id);
    if (data) {
      const model = this.createModel(data);
      model.fromStorageData(data);
      return model;
    } else {
      return null;
    }
  }

  async loadSoloBySlug(slug: string): Promise<Model> {
    const data = await this.fetchMetadataBySlug(slug);
    if (data) {
      const model = this.createModel(data);
      model.fromStorageData(data);
      return model;
    } else {
      return null;
    }
  }

  async fetchMetadataById(id: string): Promise<Data> {
    console.log(`${this.collectionName}.fetchMetadataById(${id})`);
    if (isEmpty(id)) {
      return null;
    }
    const docSnapshot = await this.docRef(id).get();
    return docSnapshot.data();
  }

  async fetchMetadataBySlug(slug: string): Promise<Data> {
    console.log(`${this.collectionName}.fetchMetadataBySlug(${slug})`);
    if (isEmpty(slug)) {
      return null;
    }
    const querySnapshot = await this.collectionRef()
      .where('slug', '==', slug)
      .get();
    const doc = querySnapshot.docs[0];
    return doc?.data();
  }

  async create(model: Model): Promise<Model> {
    return this.createWithDto(model.toStorageData());
  }

  async createWithDto(dto: Data): Promise<Model> {
    // const data = model.toStorageData();
    // honor provided id to allow single unit volume to share id with its single unit
    const id = isEmpty(dto.id) ? randomString(ID_LENGTH) : dto.id;
    this.applyTimestamps(dto);
    const data = { ...dto, id, createdAt: epochSecondsFloat() };

    console.log(`${this.collectionName}.create(${JSON.stringify(data)})`);

    const model = this.getOrCreateById(id);
    // model.fromStorageData(dto); was nuking the channel id

    await this.docRef(data.id).set(data);

    return model;
  }

  async update(model: Model): Promise<void> {
    await this.store(model.id, model.toStorageData());
  }

  async updatePartial(id: string, data: object): Promise<void> {
    // console.log(`${this.collectionName}.updatePartial(${JSON.stringify(data)})`);
    this.applyTimestamps(data as BaseMetadata);
    // todo: factor out a docSetMerge() function with this guard
    if (isEmpty(data)) {
      // an empty object object will nuke the entire doc!
      return;
    }
    await this.docRef(id).set(data, { merge: true });
  }

  // todo: return latest version of model
  async store(id: string, data: Data): Promise<void> {
    // console.log(`${this.collectionName}.update(${JSON.stringify(data)})`);
    this.applyTimestamps(data);
    await this.docRef(id).set(data, { merge: false });
  }

  applyTimestamps(data: BaseMetadata) {
    // if (!data.createdAt) {
    //   data.createdAt = epochSecondsFloat();
    // }
    data.updatedAt = epochSecondsFloat();
  }

  async delete({ id }: { id: string }): Promise<void> {
    console.log(`${this.collectionName}.delete(${id})`);
    await this.docRef(id).delete();

    runInAction(() => {
      this.modelMap.delete(id);
    });
  }

  destroyArchived = async () => {
    runInAction(async () => {
      const list = [...this.archivedList]; // make a shallow copy to isolate the loop data from mutation
      for (const item of list) {
        // console.log(item.destroy);
        await item.destroy();
      }
    });
  };

  get selectOptions() {
    return sortBy(
      this.list.map(model => ({ value: model.id, label: model.selectLabel })),
      _ => _.label?.toLocaleLowerCase()
    );
  }

  get selectOptionsWithBlank() {
    return [blankOption, ...this.selectOptions];
  }

  get selectOptionsWithAll() {
    return [allOption, ...this.selectOptions];
  }
}
