import { phraseize, transformPlayerText } from '@utils/content-string-utils';
import { LocaleCode } from '@utils/util-types';
import { notEmpty } from '@utils/conditionals';

import { InfoV5Data, WorkflowStatus } from '../catalog-types';

import { Unit } from '../models/unit';
import { UnitCaliDataLoader } from './unit-cali-data-loader';
import { Volume } from '../models/volume';
import {
  ActivityGuideV3Data,
  ChapterCaliData,
  CreditCaliData,
  NoteData,
  PassageData,
  UnitCaliData,
  VolumeCaliData,
} from './cali-catalog-types';
import { Chapter, ElementNode, Structural } from '../ingestion-aliases';
import { PlayerCaliDataBuilder } from './player-cali-data-builder';
import { EKind } from '@masala-lib/element-kinds';
import { notEmptyOrNA } from '@utils/string-utils';
import { stripChapterNoteOrderAnnotation } from '@masala-lib/misc/editorial-string-utils';
import { ClientElement } from '@tikka/client/client-types';
import { createLogger } from '@app/logger';
import { looseDateToIso8601 } from '@masala-lib/utils';
import { isEmpty } from 'lodash';
const log = createLogger('volume-cali-data-builder');

//
// generate the catalog level data and detail screen (vocab/notes) volume level asset
//

export const enum VolumeDataFlavor {
  CATALOG = 'CATALOG',
  DETAIL = 'DETAIL',
  // LISTENING_GUIDE = 'LISTENING_GUIDE', // flavor of data needed by 11ty pdf/rtf teacher assets
}

const DEFAULT_THEME_COLOR = '#8A60AB';
export class VolumeCaliDataBuilder {
  volume: Volume;
  flavor: VolumeDataFlavor;
  l1Code: LocaleCode;
  l2Code: LocaleCode;

  unitBuilders: PlayerCaliDataBuilder[] = []; // one loader/builder for each unit
  activityGuideData: ActivityGuideV3Data = null;
  // locale: LocaleCode;

  // hack until i factor out a UnitCaliDataBuilder
  // holds the unit iteration context
  currentBuilder: PlayerCaliDataBuilder;

  static async create(
    volume: Volume,
    flavor: VolumeDataFlavor = VolumeDataFlavor.CATALOG
  ) {
    const result = new VolumeCaliDataBuilder(volume, flavor);
    await result.init();
    return result;
  }

  constructor(
    volume: Volume,
    flavor: VolumeDataFlavor /*, locale: LocaleCode*/
  ) {
    this.volume = volume;
    this.flavor = flavor;
    this.l1Code = volume.l1;
    this.l2Code = volume.l2;
    // this.locale = locale;
  }

  async init(): Promise<void> {
    for (const unit of this.volume.units) {
      // const loader = await UnitCaliDataLoader.create(unit);
      // this.unitLoaders.push(loader);
      if (unit.canIngest && unit.visible(WorkflowStatus.IN_PROGRESS)) {
        const builder = await PlayerCaliDataBuilder.create(unit);
        this.unitBuilders.push(builder);
      } // silently ignore units not yet ready for ingestion
    }
    this.activityGuideData = await this.volume.buildActivityGuideCatalogData();
  }

  buildCatalogData(): VolumeCaliData {
    return this.buildData(VolumeDataFlavor.CATALOG);
  }

  buildDetailData(): VolumeCaliData {
    return this.buildData(VolumeDataFlavor.DETAIL);
  }

  unitBuilder(unit: Unit): PlayerCaliDataBuilder {
    return this.unitBuilders.find(b => b.unit.slug === unit.slug);
  }

  // buildListeningGuideData(): object {
  //   return this.buildData(VolumeDataFlavor.LISTENING_GUIDE);
  // }

  buildData(flavor: VolumeDataFlavor = null): VolumeCaliData {
    if (flavor) {
      this.flavor = flavor;
    } // otherwise use constructor default
    const volume = this.volume;
    const volumeInfo = this.volume.data.infoV5 || ({} as InfoV5Data);

    const firstUnitData = volume.firstUnit?.data;
    const unitInfo = firstUnitData?.infoV5 || ({} as InfoV5Data);

    const channelSlug = volume.channel?.data?.clientChannelSlug;

    const title = transformPlayerText(
      resolveInfo('titleL2', volumeInfo, unitInfo)
    );
    const tagline = transformPlayerText(
      resolveInfo('taglineL1', volumeInfo, unitInfo)
    );
    const description = transformPlayerText(
      resolveInfo('descriptionL1', volumeInfo, unitInfo)
    );
    const weblink = resolveInfo('weblink', volumeInfo, unitInfo);
    const originalBroadcastDate = resolveInfo(
      'originalBroadcastDate',
      volumeInfo,
      unitInfo
    );
    const seasonNumber = resolveInfo('seasonNumber', volumeInfo, unitInfo);

    const releaseDate =
      volume.data.releaseDate ||
      looseDateToIso8601(volume.firstUnit?.data?.releaseDate);

    // assumes image url data already migrated from unit to volume
    const listImageUrl = volume.data.imageThumbStorageUrl;

    const themeColor = notEmpty(volume.data.themeColor)
      ? volume.data.themeColor
      : DEFAULT_THEME_COLOR;

    const units: UnitCaliData[] = this.unitBuilders.map(builder =>
      this.buildUnitCatalogData(builder)
    );
    const chapterCount = units.reduce(
      (sum, unit) => sum + unit.chapters.length,
      0
    );

    const result: VolumeCaliData = {
      // assume already updated by ingester at start of ingestion
      version: volume.data.pendingVersion,
      volumeDataUrl: volume.data.pendingDataUrl,

      // ingestedAt: epochSecondsFloat(),
      ingestedAt: new Date().toISOString(), // ios date time
      // ingestedAtIso: new Date().toISOString(),

      // entityType: 'volume',
      slug: volume.clientSlug,
      l1Code: this.l1Code,
      l2Code: this.l2Code,
      channelSlug,

      // info: this.buildInfo(),
      title,
      tagline,
      description,
      weblink,
      seasonNumber,
      originalBroadcastDate,

      releaseDate,
      trial: volume.data.trial,

      credits: this.buildCredits(),
      speakers: volume.clientSpeakerDatas(this.l1Code),

      topics: volume.topicTagNames,
      countries: volume.countryTagNames,
      apTags: volume.apTagNames,
      ibTags: volume.ibTagNames,
      topicSlugs: volume.topicTagSlugs,
      countrySlugs: volume.countryTagSlugs,
      apTagSlugs: volume.apTagSlugs,
      ibTagSlugs: volume.ibTagSlugs,

      activityGuideData: this.activityGuideData,

      // unitCount: volume.unitCount,
      // unitSlugs: volume.unitSlugs,
      // totalDownloadSize: volume.totalDownloadSize,

      totalDurationMinutes: volume.totalDurationMinutes,
      chapterCount, // for the convenience of the listening worksheet generation

      listImageUrl,
      themeColor,

      // state: volume.data.workflowStatus,
      // price: 'n/a',

      units,
    };
    return result;
  }

  // buildInfo(): InfoCaliData {
  //   const volumeInfo = this.volume.data.infoV5 || ({} as InfoV5Data);

  //   return {
  //     title: transformPlayerText(volumeInfo.titleL2),
  //     tagline: transformPlayerText(volumeInfo.taglineL1),
  //     description: transformPlayerText(volumeInfo.descriptionL1),

  //     weblink: volumeInfo.weblink,
  //     originalBroadcastDate: volumeInfo.originalBroadcastDate,
  //     seasonNumber: volumeInfo.seasonNumber,
  //   };
  // }

  buildCredits(/*creditsData: CreditsData*/): CreditCaliData[] {
    const creditsData = this.volume.data.creditsData;
    if (!creditsData) {
      return [];
    }
    return Object.entries(creditsData)
      .map(([key, value]) => ({
        labelSlug: key,
        label: phraseize(key), // L1 - todo: look up label slug translations
        name: value,
      }))
      .filter(d => notEmpty(d.name));
    // todo: deterministic ordering
  }

  buildUnitCatalogData(unitBuilder: PlayerCaliDataBuilder): UnitCaliData {
    this.currentBuilder = unitBuilder;
    const unit = unitBuilder.unit;
    const chapters: ChapterCaliData[] = []; // populated below

    const elements =
      this.flavor === VolumeDataFlavor.DETAIL
        ? this.buildStoryDetailElementsData()
        : [];

    const bogotaVocabMigrationMap =
      this.flavor === VolumeDataFlavor.DETAIL
        ? unit.data.bogotaVocabMigrationMap
        : {};

    const result: UnitCaliData = {
      slug: unit.clientSlug,
      volumeSlug: this.volume.clientSlug, // parent ref

      unitNumber: unit.unitNumber,

      chapters,
      durationMillis: undefined, // calculated and stuff in below

      // only included in separate volume level ingestion assets
      elements, // chapter and notation elements needed to build vocab lookup data
      bogotaVocabMigrationMap,
    };

    const { truncateChapterPosition } = unitBuilder.unit.data;

    for (const node of unitBuilder.elementNodes) {
      if (node.element.kind === EKind.CHAPTER) {
        const chapterData = this.buildChapterCatalogData(unit, node, result);
        chapters.push(chapterData);
        // todo: deal with gaps

        if (
          truncateChapterPosition &&
          chapters.length >= truncateChapterPosition
        ) {
          log.info(
            `truncating ingest after ${truncateChapterPosition} chapters`
          );
          break;
        }
      } else {
        throw new Error('Script data must start with a chapter');
      }
    }

    const durationMillis = result.chapters.reduce(
      (sum, chapter) => sum + chapter.durationMillis,
      0
    );
    result.durationMillis = durationMillis;

    return result;
  }

  buildChapterCatalogData(
    unit: Unit,
    chapterNode: ElementNode,
    parent: any
  ): ChapterCaliData {
    const index = parent.chapters.length;

    const unitNumber = unit.data.unitNumber;
    const position = index + 1;
    const slug = `${unit.clientSlug}.${unitNumber}.${position}`;

    const chapterElement = chapterNode.element as Chapter;
    // const [audioStartAbsolute, endTime, durationMillis] = nodeToTimeInterval(chapterNode, content);
    // const audioStartAbsolute = chapterElement.time;
    const durationMillis = chapterElement.endTime - chapterElement.time;

    // no longer needed at the catalog level, let the player logic resolve
    // this is wrong, need to scope to chapter
    // const markCompleteMillis = this.currentBuilder.loader.markCompleteMillis;
    // const markCompleteMillis = durationMillis; // todo

    // const normalAudioUrl = ''; // assigned at later stage
    const notes: NoteData[] = [];
    const playerDataUrl = unit.data.chapterDataUrls
      ? unit.data.chapterDataUrls[index]
      : null;
    // const audioUrl = this.currentBuilder.audioSlicings[index]?.finalAudioUrl;
    const normalAudioUrl = unit.data.audioSlicings
      ? unit.data.audioSlicings[index]?.finalAudioUrl
      : null;

    const unitBuilder = this.unitBuilder(unit);
    const title = unitBuilder.chapterTitle(chapterElement);

    const result = {
      // entityType: 'chapter',
      slug,
      unitNumber,
      position,
      storySlug: unit.volume.clientSlug, // conceptual parent ref

      title,
      // titleEnglish: this.resolveTranslation(chapterNode),
      // beware, these are duplicated in excerpt data gen
      // audioStartAbsolute, //temp until audio slicing
      durationMillis,
      // markCompleteMillis,
      notes,
      normalAudioUrl,
      playerDataUrl, // cali player data
    } as ChapterCaliData;

    if (this.flavor === VolumeDataFlavor.DETAIL) {
      result.notes = this.buildChapterNotes(chapterNode);
      result.passages = this.buildChapterPassages(chapterNode, unitBuilder);
    }

    // if (this.flavor === VolumeDataFlavor.LISTENING_GUIDE) {
    //   result.passages = this.buildChapterPassages(chapterNode);
    // }

    // position: number;
    // title: string;
    // durationMillis: number;
    // playerDataUrl: string; // jwnext player data
    // markCompleteMillis: number; // not needed for top level catalog, but probably not worth trimming
    // notes?: NoteData[]; // needed to show notes from the story detail page, not needed in top level catalog

    return result;
  }

  buildChapterNotes(chapterNode: ElementNode): NoteData[] {
    const notes: NoteData[] = [];
    for (const node of chapterNode.children) {
      // console.log(`chapter child: ${JSON.stringify(node.element)}`);
      if (node.element.kind === EKind.CHAPTER_NOTE) {
        // console.log(`chapter note seen: ${JSON.stringify(node.element)}`);
        const noteElement = node.element as Structural;
        let body = this.resolveTranslation(node);
        if (isEmpty(body)) {
          // fallback to L2 hint for legacy import data
          // todo: should ideally be trimming during import/edit
          body = transformPlayerText(noteElement.content.text);
        }
        body = stripChapterNoteOrderAnnotation(body);
        notes.push({ id: noteElement.id, body });
      }
    }

    return notes;
  }

  buildChapterPassages(
    chapterNode: ElementNode,
    unitBuilder: PlayerCaliDataBuilder
  ): PassageData[] {
    const passages: PassageData[] = [];
    for (const node of chapterNode.children) {
      if (node.element.kind === EKind.PASSAGE) {
        // console.log(`passage hint seen: ${JSON.stringify(node.element)}`);
        const passageElement = node.element as Structural;
        // let hint = this.resolveTranslation(node);
        // // need unit
        // if (isEmpty(hint)) {
        //   // fallback to L2 hint for legacy import data
        //   // todo: should ideally be trimming during import/edit
        //   hint = transformPlayerText(passageElement.content.text);
        // }
        // hint = stripChapterNoteOrderAnnotation(hint);
        const clientPassageData = unitBuilder.toClientPassage(passageElement);
        const hint = clientPassageData.hint.l1;

        passages.push({ id: passageElement.id, hint });
      }
    }

    return passages;
  }

  resolveTranslation = (node: ElementNode): string => {
    const loader = this.currentBuilder.loader;
    // todo: think more about empty string vs null/undefined
    return (loader.translationSet[node.element.id]?.content as string) || null;
  };

  buildStoryDetailElementsData(
    loader: UnitCaliDataLoader = null
  ): ClientElement[] {
    if (!loader) {
      loader = this.currentBuilder.loader;
    }
    const dataBuilder = new PlayerCaliDataBuilder(
      loader.unit,
      this.l1Code,
      loader
    );
    const elements = dataBuilder.buildStoryDetailElementsData();
    return elements;
  }
}

const resolveInfo = (
  prop: keyof InfoV5Data,
  infoA: InfoV5Data,
  infoB: InfoV5Data
) => {
  const valueA = (infoA || ({} as InfoV5Data))[prop];
  if (notEmptyOrNA(valueA)) {
    return valueA;
  }
  const valueB = (infoB || ({} as InfoV5Data))[prop];
  if (notEmptyOrNA(valueB)) {
    return valueB;
  }
  return '';
};
